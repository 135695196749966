<template>
	<v-row class="px-4">
		<v-col md="8" offset-md="4" class="py-0" v-if="false">
			<GoogleSearchInput></GoogleSearchInput>
		</v-col>
		<v-col v-if="isLocation" md="12" class="my-auto py-0">
			<v-checkbox
				dense
				v-model="address.has_lat_long"
				:false-value="0"
				:true-value="1"
				hide-details
				class="m-0 p-0"
				label="Provide coordinates of this Location"
			></v-checkbox>
		</v-col>
		<template v-if="type == 2">
			<v-col md="4" class="my-auto py-0">
				<label :for="`address-name-${stringId}`" class="btx-label mt-2 required">Contact Person</label>
			</v-col>
			<v-col md="8" class="py-0">
				<SelectInput
					hide-details
					:disabled="disabled"
					:loading="loading"
					append-outer-icon="mdi-cog"
					:items.sync="contactPersonList"
					id="contact_person"
					placeholder="Contact Person"
					item-text="full_name"
					item-value="id"
					v-model="address.contact_person"
					v-on:click:append-outer="contactPersonDialog = true"
					:rules="[vrules.required(address.contact_person, 'Contact Person')]"
				></SelectInput>
			</v-col>
		</template>
		<template v-if="false">
			<v-col md="4" class="my-auto py-0">
				<label :for="`address-name-${stringId}`" class="btx-label mt-2 required"
					><template v-if="!isLocation">Address</template> Name</label
				>
			</v-col>
			<v-col md="8" class="py-0">
				<TextInput
					hide-details
					:id="`address-name-${stringId}`"
					placeholder="Name"
					v-model="address.name"
					:rules="[vrules.required(address.name, 'Name')]"
					:class="{
						required: !address.name,
					}"
					:disabled="disabled"
					:loading="loading"
				></TextInput>
			</v-col>
		</template>
		<v-col md="4" class="my-auto py-0">
			<label :for="`address-postalcode-${stringId}`" class="btx-label mt-2 required"
				>Postal Code</label
			>
		</v-col>
		<v-col md="8" class="py-0">
			<PostalCodeTemplate
				hide-details
				:id="`address-postalcode-${stringId}`"
				placeholder="Postal Code"
				v-model="address.address_postal_code"
				:rules="[vrules.required(address.address_postal_code, 'Postal Code')]"
				:disabled="disabled"
				:loading="loading"
			></PostalCodeTemplate>
		</v-col>
		<template v-if="isLocation">
			<v-col md="4" class="my-auto py-0">
				<label :for="`id-number-${stringId}`" class="btx-label mt-2"
					>Identification Number
					<TooltipQuestion>
						<template v-slot:text
							>Your unique identifier for this<br />Location. It can also be printed<br />and scanned as a
							barcode.</template
						>
					</TooltipQuestion>
				</label>
			</v-col>
			<v-col md="8" class="py-0">
				<TextInput
					hide-details
					:disabled="disabled"
					:loading="loading"
					:id="`id-number-${stringId}`"
					placeholder="Identification Number"
					v-model="address.id_number"
				></TextInput>
			</v-col>
		</template>
		<template v-if="address.has_lat_long">
			<v-col md="4" class="my-auto py-0">
				<label :for="`address-latitude-${stringId}`" class="btx-label mt-2">Latitude</label>
			</v-col>
			<v-col md="8" class="py-0">
				<TextInput
					hide-details
					:id="`address-latitude-${stringId}`"
					placeholder="Latitude"
					v-model="address.latitude"
					:disabled="disabled"
					:loading="loading"
				></TextInput>
			</v-col>
			<v-col md="4" class="my-auto py-0">
				<label :for="`address-longitude-${stringId}`" class="btx-label mt-2">Longitude</label>
			</v-col>
			<v-col md="8" class="py-0">
				<TextInput
					hide-details
					:id="`address-longitude-${stringId}`"
					placeholder="Longitude"
					v-model="address.longitude"
					:disabled="disabled"
					:loading="loading"
				></TextInput>
			</v-col>
		</template>
		<v-col md="4" class="mt-2 py-0">
			<label :for="`address-street-1-${stringId}`" class="btx-label mt-2 required">Address</label>
		</v-col>
		<v-col md="8" class="py-0">
			<TextAreaInput
				hide-details
				:id="`address-street-1-${stringId}`"
				placeholder="Street 1"
				v-model="address.address_line_1"
				:rules="[vrules.required(address.address_line_1, 'Address')]"
				:disabled="disabled"
				:loading="loading"
			></TextAreaInput>
		</v-col>
		<template v-if="false">
			<v-col md="4" class="py-0"> </v-col>
			<v-col md="8" class="py-0">
				<TextAreaInput
					hide-details
					:id="`address-street-2-${stringId}`"
					placeholder="Street 2"
					v-model="address.address_line_2"
					:disabled="disabled"
					:loading="loading"
				></TextAreaInput>
			</v-col>
		</template>
		<template v-if="false">
			<v-col md="4" class="my-auto py-0">
				<label :for="`address-country-${stringId}`" class="btx-label mt-2">Country / Region</label>
			</v-col>
			<v-col md="8" class="py-0">
				<AutoCompleteInput
					hide-details
					:items.sync="countries"
					item-text="name"
					item-value="name"
					:id="`address-country-${stringId}`"
					placeholder="Country / Region"
					v-model="address.address_country"
					:disabled="countryLoading || disabled"
					:loading="countryLoading || loading"
				></AutoCompleteInput>
			</v-col>
		</template>
		<template v-if="isLocation">
			<v-col md="4" class="my-auto py-0">
				<label :for="`address-location-${stringId}`" class="btx-label mt-2">Nest Location under</label>
			</v-col>
			<v-col md="8" class="py-0">
				<AutoCompleteInput
					hide-details
					:items="locationList"
					:disabled="disabled"
					:loading="loading"
					:id="`address-location-${stringId}`"
					placeholder="Nest Location under"
					v-model="address.location"
				></AutoCompleteInput>
			</v-col>
			<v-col md="4" class="py-0 mt-2">
				<label :for="`address-description-${stringId}`" class="btx-label mt-2">Description</label>
			</v-col>
			<v-col md="8" class="py-0">
				<TextAreaInput
					hide-details
					:id="`address-description-${stringId}`"
					placeholder="Description"
					v-model="address.description"
					:disabled="disabled"
					:loading="loading"
				></TextAreaInput>
			</v-col>
		</template>

		<template v-if="uuid && contactPersonDialog">
			<CreateContactPerson
				:person-dialog="contactPersonDialog"
				:contactUuid="uuid"
				v-on:close="contactPersonDialog = false"
				v-on:success="getContactPersonList"
			></CreateContactPerson>
		</template>
	</v-row>
</template>
<script>
import { mapGetters } from "vuex";
import PostalCodeTemplate from "@/view/components/PostalCode";
import TextInput from "@/view/components/TextInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import TooltipQuestion from "@/view/components/TooltipQuestion";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import GoogleSearchInput from "@/view/components/GoogleSearchInput";
import ApiService from "@/core/services/api.service";
import { isObject, isEqual } from "lodash";
import CreateContactPerson from "@/view/components/CreateOrUpdatePerson";
import SelectInput from "@/view/components/SelectInput";

export default {
	name: "btx-address",
	title: "Address",
	model: {
		prop: "value",
		event: "input",
	},
	props: {
		value: {
			type: [Object, String],
			default: null,
		},
		isLocation: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		type: {
			type: Number,
			default: 1,
		},
		contactPersonList: {
			type: [Object, Array],
			default: new Array(),
		},
		uuid: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			countries: [],
			states: [],
			cities: [],
			contactPersons: [],
			locationList: [],
			countryLoading: false,
			contactPersonDialog: false,
			stateLoading: false,
			cityLoading: false,
			address: {
				id: null,
				uuid: null,
				name: null,
				id_number: null,
				contact_person: 0,
				location: null,
				latitude: null,
				longitude: null,
				address_line_1: null,
				address_line_2: null,
				address_country: "Singapore",
				address_postal_code: null,
				description: null,
				has_lat_long: 0,
			},
		};
	},
	watch: {
		address: {
			deep: true,
			handler(param) {
				this.$emit("input", param);
			},
		},
		value: {
			deep: true,
			handler() {
				this.updateAddress();
			},
		},
	},
	methods: {
		getContactPersonList() {
			this.$emit("updateContactPersonList", true);
		},
		updateAddress() {
			if (isObject(this.value) && !isEqual(this.value, this.address)) {
				this.address = {
					id: this.value.id || null,
					uuid: this.value.uuid || null,
					name: this.value.name || null,
					id_number: this.value.id_number || null,
					location: this.value.location || null,
					latitude: this.value.latitude || null,
					longitude: this.value.longitude || null,
					contact_person: this.value.contact_person || null,
					address_line_1: this.value.address_line_1 || null,
					address_line_2: this.value.address_line_2 || null,
					address_city: this.value.address_city || null,
					address_state: this.value.address_state || null,
					address_country: this.value.address_country || null,
					address_postal_code: this.value.address_postal_code || null,
					description: this.value.description || null,
					has_lat_long: this.value.has_lat_long || 0,
				};
			}
			this.$nextTick(() => {
				if (this.address.address_country) {
					this.getStates();
					if (this.address.address_state) {
						this.getCities();
					}
				}
			});
		},
		getCountries() {
			const countries = localStorage.getItem("countries");
			if (countries) {
				this.countries = JSON.parse(countries);
			} else {
				this.countries = [
					{
						name: "Singapore",
						code: "SG",
						dial_code: "+65",
					},
				];
				localStorage.setItem("countries", JSON.stringify(this.countries));
			}
		},
		getStates() {
			const { address_country } = this.address;
			const key = `states-${address_country}`.toLowerCase();
			const state = localStorage.getItem(key);
			if (state) {
				this.states = JSON.parse(state);
			} else {
				if (this.stateLoading) {
					return false;
				}
				this.stateLoading = true;
				ApiService.post("https://countriesnow.space/api/v0.1/countries/states", {
					country: address_country,
				})
					.then(({ data }) => {
						const { states } = data;
						this.states = states;
						localStorage.setItem(key, JSON.stringify(states));
					})
					.catch((error) => {
						console.log({ error });
					})
					.finally(() => {
						this.stateLoading = false;
					});
			}
		},
		getCities() {
			const { address_country, address_state } = this.address;
			const key = `cities-${address_country}-${address_state}`.toLowerCase();
			const cities = localStorage.getItem(key);
			if (cities) {
				this.cities = JSON.parse(cities);
			} else {
				if (this.cityLoading) {
					return false;
				}
				this.cityLoading = true;
				ApiService.post("https://countriesnow.space/api/v0.1/countries/state/cities", {
					country: address_country,
					state: address_state,
				})
					.then(({ data }) => {
						this.cities = data;
						localStorage.setItem(key, JSON.stringify(data));
					})
					.catch((error) => {
						console.log({ error });
					})
					.finally(() => {
						this.cityLoading = false;
					});
			}
		},
	},
	components: {
		TextInput,
		TooltipQuestion,
		GoogleSearchInput,
		CreateContactPerson,
		AutoCompleteInput,
		PostalCodeTemplate,
		TextAreaInput,
		SelectInput,
	},
	computed: {
		...mapGetters(["localDB"]),
		stringId() {
			return this.stringUnique();
		},
	},
	mounted() {
		this.locationList = this.localDB("locations", []);
		this.updateAddress();
		this.getCountries();
		this.address.address_country = "Singapore";
	},
};
</script>
